import React from 'react'
import { useShiftEditor } from '../useShiftEditor'
import { useCompanyInfo } from 'queries/company'
import { Box, Flex, Text, Tooltip } from 'ui'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import styled from 'styled-components'
import { PaymentTypeEnum } from '../schemas'
import { formatCurrency } from 'lib/string'
import { CalendarIcon, InfoIcon } from 'lucide-react'
import { usePreviewData } from './usePreviewData'
import { format } from 'date-fns'
import { WeekCalendar } from './WeekCalendar'

const CompanyLogo = styled(Box).attrs({
  boxShadow: 'low',
  borderRadius: 'standard',
})`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 6rem;
  width: 6rem;
  margin-top: -4rem;
`

export function ShiftHeader() {
  const { shiftData } = useShiftEditor()
  const { data: company } = useCompanyInfo()
  const position = useCompanyPosition(shiftData?.details?.positionId)
  const { estimatedPay, date, time } = usePreviewData()

  if (!shiftData && !company) return null

  return (
    <Box
      boxShadow={'low'}
      borderRadius={'standard'}
      bg={'white'}
      p={3}
      mt={5}
      mx={3}
      mb={3}
    >
      <Flex flexDirection={'column'} alignItems={'center'}>
        <CompanyLogo style={{ backgroundImage: `url(${company?.logoUrl})` }} />
        <Text fontWeight={2} mt={3}>
          {position?.name}
        </Text>
        <Text fontSize={0}>{company?.name}</Text>
        {shiftData.payment?.paymentType === PaymentTypeEnum.Hourly ? (
          <Text fontWeight={2} mt={1}>
            {formatCurrency(shiftData.payment?.pay)}/hr
          </Text>
        ) : null}
        {estimatedPay ? (
          <Box display={'inline-flex'} alignItems={'center'} color={'primary'}>
            <Text fontWeight={2} mt={1} color={'text'} mr={2}>
              Estimated Pay {formatCurrency(estimatedPay)}
            </Text>
            <Tooltip
              content={
                <Text>
                  This is an estimate for preview purpose only.
                  <br />
                  The actual amount worker sees in the app may be different.
                </Text>
              }
            >
              <InfoIcon size={16} />
            </Tooltip>
          </Box>
        ) : null}
        <Box
          display={'inline-flex'}
          alignItems={'center'}
          borderTop={'1px solid'}
          borderTopColor={'neutrals.100'}
          mt={2}
          py={2}
          fontSize={1}
        >
          <CalendarIcon size={14} />
          &nbsp;&nbsp;
          {date ? format(date, 'MMM do') : null}
          &nbsp;•&nbsp;{time}
        </Box>
      </Flex>
      <WeekCalendar />
    </Box>
  )
}
