import { api } from './api'
import { config } from 'config'

export function graphql(q, ctx, options = {}) {
  let headers = {}
  if (ctx) {
    headers = { 'APP-GQL-CONTEXT': ctx }
  }

  return api.post(
    config.graphqlHostUrl,
    {
      query: `query { ${q} }`,
    },
    { headers, ...options }
  )
}
