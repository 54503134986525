import { DemoCategory } from './types'
import { api } from '../api'
import { getMockData } from './mocks'
import { clearDemoData, setDemoData } from 'api/demo/utils'
import { createMockLocationsForCategory } from 'api/demo/mocks/createLocation'
import { createMockPositionsForCategory } from 'api/demo/mocks/createPosition'
import { createMockWorkersForCategory } from 'api/demo/mocks/createWorkers'
import { createMockRostersForCategory } from 'api/demo/mocks/createRoster'
import { createMockShiftsForCategory } from 'api/demo/mocks/createShift'
import { companyMembers } from 'api/demo/data/companyMembers'
export { isDemoMode } from './utils'
export * from './demoCompany'

let interceptorId: number

export function createApiInterceptor() {
  interceptorId = api.interceptors.request.use(async (config) => {
    const mockData = await getMockData(config)
    if (mockData !== undefined) {
      config.adapter = (config) => {
        return Promise.resolve({
          data: mockData,
          status: 200,
          statusText: 'OK',
          headers: {},
          config,
        })
      }
    }
    return config
  })
}

export function removeApiInterceptor() {
  if (interceptorId) {
    api.interceptors.request.eject(interceptorId)
  }
}

export async function activateDemoMode(category: DemoCategory) {
  const workers = await createMockWorkersForCategory()
  const locations = createMockLocationsForCategory(category)
  const positions = createMockPositionsForCategory(category)
  const { shifts, supervisors } = createMockShiftsForCategory(
    locations,
    positions,
    workers
  )
  const rosters = createMockRostersForCategory(category, workers)
  const demoData = {
    category,
    locations,
    positions,
    workers,
    rosters,
    shifts,
    supervisors,
    companyMembers,
  }
  setDemoData(demoData)
  // set demo mode on
  createApiInterceptor()
}

export function reactivateDemoMode() {
  createApiInterceptor()
}

export function deactivateDemoMode() {
  clearDemoData()
  removeApiInterceptor()
}
