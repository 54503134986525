import React, { useState } from 'react'
import {
  Box,
  Flex,
  IconContentView,
  Text,
  Button,
  DropdownMenu,
  toast,
} from 'ui'
import { useShiftDetail } from './useShiftDetail'
import {
  CalendarClock,
  MapPin,
  DollarSign,
  LucideMoreHorizontal,
} from 'lucide-react'
import { getShiftTimezone } from 'lib/shift'
import { formatCurrency } from 'lib/string'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone } from 'lib/time'
import { fileDownload, getAddressString } from 'lib/legacy_util'
import { getSupervisorListAsString } from 'lib/supervisor'
import { getShiftBonus } from 'lib/payment'
import { PDFDownloader } from './printable_roster/PDFDownloader'
import { useCompanyInfo } from 'queries/company'
import { styled } from 'styled-components'
import useShiftTimeline from 'hooks/useShiftTimeline'
import { CancelShiftModal } from './CancelShiftModal'
import { BackupShiftAlert } from 'pages/HomePage/ShiftCard/BackupShiftAlert'
import { TutorialsInfo } from './TutorialsInfo'

export const ShiftDetailInfo = () => {
  const { shift } = useShiftDetail()
  const { data: company } = useCompanyInfo()
  const { isInPast, isInProgress } = useShiftTimeline(shift)
  const [showCancelShiftModal, setShowCancelShiftModal] = useState(false)
  if (!shift) return null

  const timezone = getShiftTimezone(shift) || getLocalTimezone()
  const startDate = new Date(shift.startsAt)
  const endDate = new Date(shift.endsAt)

  const pay = shift.payLumpSum
    ? Number(shift.payLumpSum).toFixed(2)
    : Number(shift.payRate).toFixed(2)
  const bonus = getShiftBonus(shift)

  const [downloadPDF, setDownloadPDF] = useState(false)

  const handlePrintRoster = () => {
    setDownloadPDF(true)
  }

  const handleDownloaded = () => {
    setDownloadPDF(false)
  }

  const pdfFilename = `WorkWhile_${company?.name}_shift_${shift.id}.pdf`

  const downloadShiftDetailCsv = () => {
    const toastId = toast.loading(
      'Please wait a few moments for your Shift Detail CSV to download...'
    )
    const url = `/company/${company?.id}/shift_details?shift_id=${shift.id}`
    fileDownload(url, `workwhile_shift_details_${shift.id}.csv`).then(() => {
      toast.dismiss(toastId) // Dismiss the loading toast when download is complete.
    })
  }

  const downloadPaymentEstimateCsv = () => {
    const toastId = toast.loading(
      'Please wait a few moments for your Payment Estimate CSV to download...'
    )
    const url = `/company/${company?.id}/shift_payment_estimate/${shift.id}`
    fileDownload(url, `workwhile_shift_payment_estimate_${shift.id}.csv`).then(
      () => {
        toast.dismiss(toastId) // Dismiss the loading toast when download is complete.
      }
    )
  }

  return (
    <Box mb={4}>
      <Box>
        <Flex
          flexDirection={['column', 'column', 'row', 'row']}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
        >
          <Box>
            <Box mb={3}>
              <IconContentView
                icon={<CalendarClock size={20} />}
                iconProps={{ mr: 3 }}
              >
                <Box>
                  <Text>
                    {formatInTimeZone(
                      startDate,
                      timezone,
                      'EEEE, MMMM d, yyyy'
                    )}
                  </Text>
                  <Text>
                    {formatInTimeZone(startDate, timezone, 'h:mma')} -{' '}
                    {formatInTimeZone(endDate, timezone, 'h:mma zzz')}{' '}
                    {shift?.lunchLength && shift.lunchLength > 0
                      ? `• Unpaid Break: ${shift?.lunchLength}mins`
                      : ''}
                  </Text>
                </Box>
              </IconContentView>
            </Box>
            <Box mb={3}>
              <IconContentView
                icon={<MapPin size={20} />}
                iconProps={{ mr: 3 }}
              >
                <Box>
                  <Text>
                    {shift.locationless
                      ? 'Locationless'
                      : getAddressString(shift.location?.address)}
                  </Text>
                  <Text>
                    Supervisor
                    {shift.supervisors && shift.supervisors.length > 1
                      ? 's'
                      : ''}
                    : {getSupervisorListAsString(shift)}
                  </Text>
                </Box>
              </IconContentView>
            </Box>
            <Box>
              <IconContentView
                icon={<DollarSign size={20} />}
                iconProps={{ mr: 3 }}
              >
                <Box>
                  <Text>
                    {formatCurrency(pay)}
                    {shift.payLumpSum ? ' per worker' : '/hour per worker'}
                    {bonus && Number(bonus) > 0
                      ? ` • Bonus: ${formatCurrency(bonus)}`
                      : null}
                  </Text>
                </Box>
              </IconContentView>
            </Box>
          </Box>
          <Box mt={[4, 4, 0, 0]}>
            <Flex flexDirection={'row'}>
              {downloadPDF ? (
                <PDFDownloader
                  onDownloaded={handleDownloaded}
                  pdfName={pdfFilename}
                />
              ) : null}
              <Button
                variant="secondary"
                mr={2}
                kind="medium"
                onClick={handlePrintRoster}
              >
                Print Roster
              </Button>
              <DropdownMenu
                trigger={
                  <Button variant="secondary" kind="medium">
                    <LucideMoreHorizontal size={16} />
                  </Button>
                }
              >
                <DropdownMenu.Item onSelect={downloadShiftDetailCsv}>
                  Download Shift Detail CSV
                </DropdownMenu.Item>
                {isInPast ? (
                  <DropdownMenu.Item onSelect={downloadPaymentEstimateCsv}>
                    Download Payment Estimate CSV
                  </DropdownMenu.Item>
                ) : null}
                {isInPast || isInProgress ? null : (
                  <CancelShiftItem
                    onSelect={() => setShowCancelShiftModal(true)}
                  >
                    Cancel Shift
                  </CancelShiftItem>
                )}
              </DropdownMenu>
            </Flex>
          </Box>
        </Flex>
        <TutorialsInfo />
        <BackupShiftAlert shift={shift} />
      </Box>
      <CancelShiftModal
        open={showCancelShiftModal}
        handleClose={() => setShowCancelShiftModal(false)}
      />
    </Box>
  )
}

const CancelShiftItem = styled(DropdownMenu.Item)`
  && {
    color: ${(props) => props.theme.colors.errors[200]};

    &[data-highlighted] {
      background-color: ${(props) => props.theme.colors.errors[50]};
      color: ${(props) => props.theme.colors.errors[200]};
    }
  }
`
