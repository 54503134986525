import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { AudienceType, PartialShiftData, shiftSchema } from './schemas'
import { useQueryClient } from '@tanstack/react-query'
import {
  companyLocationsKey,
  companyMembersKey,
  companyPositionsKey,
} from 'queries/company/keys'
import {
  getCompanyLocations,
  getCompanyMembers,
  getCompanyPositions,
} from 'api/company'
import { CompanyWorker, getWorkerProfile } from 'api/worker'
import { ShiftEditData } from 'api/shift'
import {
  fromServerData,
  ShiftStatusData,
  toServerData,
  getShiftStatusData,
} from './dataTransformers'
import { useSaveShiftMutation, useShiftForEdit } from 'queries/shift'
import { Loading, toast } from 'ui'
import { useHasPaymentMethod } from './useHasPaymentMethod'
import { format } from 'date-fns'
import { equals } from 'ramda'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
import { useNavigateWithContext } from 'pages/ShiftEditorPage/useNavigateWithContext'
import { EditorMode, ShiftType, Step, StepEnum } from './types'
import { getStepsByShiftType } from './constants'

export interface ShiftEditorContextValues {
  shiftId?: number
  isNew: boolean
  isShiftSaved: boolean
  isTryout: boolean
  editorMode: EditorMode
  shiftType: ShiftType
  shiftData: PartialShiftData
  shiftStatusData: ShiftStatusData
  steps: Step[]
  currentStep?: StepEnum
  goTo: (stepName: StepEnum, replace?: boolean) => void
  goNext: () => void
  setShiftType: (shiftType: ShiftType) => void
  setEditorMode: (editorMode: EditorMode) => void
  setShiftData: (data: PartialShiftData) => void
  hasRequiredData: () => boolean
  loadShiftData: (data: ShiftEditData) => void
  resetShiftData: () => void
  editorError?: string
  submitShiftData: () => void
  isSaving: boolean
  markStepSeen: (step: StepEnum) => void
  confirmationSeen: boolean
  workerToShiftLeadRatio: number
  hasPaymentMethod: boolean
  firstUnfinishedStep?: Step
  canGoTo: (stepName: StepEnum) => boolean
  isDirty: boolean
  editData?: ShiftEditData
}

const isDev = process.env.NODE_ENV !== 'production'

export const ShiftEditorContext =
  createContext<ShiftEditorContextValues | null>(null)

export const ShiftEditorProvider = (props: PropsWithChildren) => {
  const { children } = props
  const { shiftId: shiftIdParam } = useParams<{ shiftId: string }>()
  const shiftId =
    shiftIdParam && shiftIdParam !== 'new' ? Number(shiftIdParam) : undefined
  const { location, navigate } = useNavigateWithContext()
  const [searchParams] = useSearchParams()
  const [editorMode, setEditorMode] = useState(EditorMode.Standard)
  const [shiftType, setShiftType] = useState(ShiftType.Regular)
  const [shiftData, setShiftData] = useState<PartialShiftData>({})
  const [shiftStatusData, setShiftStatusData] = useState<ShiftStatusData>({
    workerStatus: {},
  })
  const { value: isInternalCompany } = useGate(
    FeatureGates.InternalCompanyTreatment
  )
  const queryClient = useQueryClient()
  const [error, setError] = useState<string | undefined>()
  const [seenSteps, setSeenSteps] = useState<StepEnum[]>([])
  const [isLoadingWorkers, setLoadingWorkers] = useState(false)
  const referenceId = searchParams.get('reference_id')
    ? Number(searchParams.get('reference_id'))
    : undefined
  const hasPaymentMethod = useHasPaymentMethod()

  const steps = useMemo(() => {
    return getStepsByShiftType(shiftType)
  }, [shiftType])

  const firstUnfinishedStep = useMemo(() => {
    return steps.find((step) => {
      if (step.schemaKey && step.schema) {
        const data = shiftData[step.schemaKey]
        const { success } = step.schema.safeParse({
          ...data,
          ...(step.schemaKey === 'payment'
            ? {
                allowZeroPay: isInternalCompany,
              }
            : {}),
        })
        if (!success) {
          return true
        }
      }
      return false
    })
  }, [steps, shiftData, isInternalCompany])
  const [isDirty, setIsDirty] = useState(false)

  const { data: editData, isLoading: isLoadingShift } = useShiftForEdit({
    shiftId: shiftId ?? referenceId,
  })

  useEffect(() => {
    if (!editData) {
      return
    }
    if (editData.isTryout) {
      setShiftType(ShiftType.Tryout)
    }
    const clientData = fromServerData(editData, !referenceId)
    // only for editing existing shift
    if (shiftId) {
      const shiftStatusData = getShiftStatusData(editData)
      setShiftStatusData(shiftStatusData)
    }
    setShiftData(clientData)

    if (referenceId) {
      goTo(StepEnum.Details, true)
    }
  }, [shiftId, referenceId, editData])

  const {
    mutate: saveShift,
    isPending: isCreatingShift,
    isSuccess: isShiftSaved,
  } = useSaveShiftMutation({
    shiftId,
    isPast: editorMode === EditorMode.PastShift,
    onError(err) {
      setError(err.message)
    },
    onSuccess(shiftDate?: Date) {
      setIsDirty(false)
      const returnUrl = shiftDate
        ? `/home/${format(shiftDate, 'yyyy-MM-dd')}`
        : '/home'

      if (shiftType === ShiftType.LongTermAssignment) {
        toast.success('Assignment created successfully')
      } else {
        toast.success(
          shiftId ? 'Shift updated successfully' : 'Shift created successfully.'
        )
      }

      // wait to ensure block checker gets the latest isDirty
      setTimeout(() => {
        navigate(returnUrl, {}, true)
      }, 100)
    },
  })

  // prefetch some data
  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: companyLocationsKey,
      queryFn: getCompanyLocations,
    })
    queryClient.prefetchQuery({
      queryKey: companyPositionsKey,
      queryFn: getCompanyPositions,
    })
    queryClient.prefetchQuery({
      queryKey: companyMembersKey,
      queryFn: getCompanyMembers,
    })
  }, [])

  useEffect(() => {
    const scheduleMode = searchParams.get('schedule')
    const workerIds = searchParams.get('worker_ids')

    if (scheduleMode === 'past') {
      setEditorMode(EditorMode.PastShift)
    }

    if (scheduleMode === 'future') {
      setShiftData({
        staff: {
          numWorkers: 0,
          restrictShiftToAudience: false,
          audienceType: AudienceType.SpecificWorkers,
          supervisors: [],
        },
      })
    }

    if (workerIds) {
      setLoadingWorkers(true)
      const ids = workerIds.split(',').map(Number)
      Promise.all(ids.map(getWorkerProfile)).then((workers) => {
        setShiftData({
          staff: {
            numWorkers: workers.length,
            restrictShiftToAudience: false,
            audienceType: AudienceType.SpecificWorkers,
            supervisors: [],
            specificWorkers: workers.map(
              (worker) => worker.worker as CompanyWorker
            ),
          },
        })
        setLoadingWorkers(false)
        goTo(StepEnum.Details, true)
      })
    } else if (scheduleMode) {
      goTo(StepEnum.Details, true)
    }
  }, [searchParams])

  const currentStep = useMemo(() => {
    return steps.find((step) => {
      if (
        location.pathname.split('/').pop() === shiftIdParam &&
        step.route === '/'
      ) {
        return true
      }

      return !!location.pathname.match(`(.+)${step.route}$`)
    })?.name
  }, [steps, shiftIdParam, location])

  const goTo = (stepName: StepEnum, replace = false) => {
    const step = steps.find((step) => step.name === stepName)
    if (step) {
      navigate(step.route, { replace })
    }
  }

  const hasRequiredData = () => {
    const { success } = shiftSchema.safeParse(shiftData)
    return success
  }

  const goNext = () => {
    if (!currentStep) {
      return navigate(steps[0].route)
    }

    // if all completed, go to Confirm
    if (hasRequiredData() && seenSteps.includes(StepEnum.Confirm)) {
      goTo(StepEnum.Confirm, true)
      return
    }

    const currentIndex = steps.findIndex((step) => step.name === currentStep)
    const nextStep = steps[currentIndex + 1]
    if (nextStep) {
      navigate(nextStep.route)
    }
  }

  const values = useMemo(
    () => ({
      shiftId,
      isNew: shiftIdParam === 'new',
      isShiftSaved,
      isTryout: shiftType === ShiftType.Tryout,
      editorMode,
      editorError: error,
      shiftType,
      shiftData,
      shiftStatusData,
      steps,
      currentStep,
      goTo,
      goNext,
      setShiftType,
      setEditorMode,
      setShiftData: (data: PartialShiftData) => {
        setShiftData((prev) => {
          const newData = { ...prev, ...data }
          const hasChange = !equals(prev, newData)
          setIsDirty(isDirty || hasChange)
          return newData
        })
      },
      resetShiftData: () => setShiftData({}),
      hasRequiredData,
      loadShiftData: (data: ShiftEditData) =>
        setShiftData(fromServerData(data)),
      submitShiftData: () => {
        if (!hasPaymentMethod) {
          setError(
            'You must add a payment method to be able to create a shift.'
          )
          return
        }

        const result = shiftSchema.safeParse(shiftData)
        if (!result.success) {
          setError(
            isDev
              ? result.error.toString()
              : 'Please double check all the information provided is correct.'
          )
          return
        }
        const submissionData = toServerData(result.data, shiftType, shiftId)
        saveShift(submissionData)
      },
      isSaving: isCreatingShift,
      markStepSeen: (step: StepEnum) => {
        setSeenSteps((seenSteps) => {
          if (seenSteps.includes(step)) {
            return seenSteps
          }
          return [...seenSteps, step]
        })
      },
      confirmationSeen: seenSteps.includes(StepEnum.Confirm),
      workerToShiftLeadRatio: 20,
      hasPaymentMethod,
      firstUnfinishedStep,
      canGoTo: (step: StepEnum) => {
        const stepItem = steps.find((stepItem) => stepItem.name === step)
        if (!stepItem) {
          return false
        }
        return (
          !firstUnfinishedStep ||
          steps.indexOf(stepItem) <= steps.indexOf(firstUnfinishedStep)
        )
      },
      isDirty,
      editData,
    }),
    [
      shiftId,
      shiftIdParam,
      currentStep,
      editorMode,
      shiftType,
      shiftData,
      error,
      isCreatingShift,
      shiftStatusData,
      seenSteps,
      hasPaymentMethod,
      firstUnfinishedStep,
      isDirty,
      editData,
      isShiftSaved,
    ]
  )

  if (isLoadingShift || isLoadingWorkers) {
    return <Loading />
  }

  if (shiftId && !shiftData.details) {
    return <Loading />
  }

  return (
    <ShiftEditorContext.Provider value={values}>
      {children}
    </ShiftEditorContext.Provider>
  )
}

export * from './types'
