import * as zod from 'zod'
import {
  selectBooleanOptionSchema,
  selectOptionSchema,
  selectNumericOptionSchema,
} from './generic'
import { getLocalTimezone } from 'lib/time'

export const orientationShiftSchema = zod
  .object({
    willHaveOrientation: zod.object(selectBooleanOptionSchema, {
      required_error: 'Please select if the assignment will have orientation',
    }),
    selectedDays: zod.array(zod.date()).optional(),
    startTime: zod.object(selectOptionSchema).optional(),
    timezone: zod.string().default(getLocalTimezone()),
    endTime: zod.object(selectOptionSchema).optional(),
    lunchLength: zod.object(selectNumericOptionSchema).optional(),
    isW2: zod.boolean().optional(),
  })
  .superRefine((data, context) => {
    if (data.willHaveOrientation.value) {
      if (!data.selectedDays || data.selectedDays.length === 0) {
        context.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Please select at least one day for orientation.',
          path: ['selectedDays'],
        })
      }
      if (!data.startTime) {
        context.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Please select a start time for orientation.',
          path: ['startTime'],
        })
      }
      if (!data.endTime) {
        context.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Please select an end time for orientation.',
          path: ['endTime'],
        })
      }
    }
    return true
  })

export type OrientationShiftForm = zod.infer<typeof orientationShiftSchema>
