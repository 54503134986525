import { ShiftEditData } from 'api/shift'
import {
  getLocalTimezone,
  getUTCDateTime,
  QUARTER_HOUR_INTERVAL_OPTIONS,
  toMinutes,
  UNPAID_BREAK_OPTIONS,
} from 'lib/time'
import {
  AudienceType,
  PartialShiftData,
  PaymentTypeEnum,
  ShiftData,
  SupervisorRequestType,
} from './schemas'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { SelectOption } from './schemas/generic'
import { BONUSES } from './pages/PaymentPage/BonusSelector'
import { addDays } from 'date-fns'
import { unmaskPhoneNumber } from 'lib/phone'
import { ShiftType } from './ShiftEditorProvider'
import { CompanyWorker } from 'api/worker'

export type WorkerStatus = 'available' | 'unavailable' | 'pending'
export interface ShiftStatusData {
  workerStatus: Record<number, WorkerStatus>
}

function getDetails(data: ShiftEditData) {
  return data.location?.id && data.position?.id
    ? {
        locationId: Number(data.location.id),
        positionId: Number(data.position.id),
        locationless: !!data.locationless,
      }
    : undefined
}

function getSchedule(data: ShiftEditData, includesDate: boolean) {
  const timezone = data.location?.address?.timezone || getLocalTimezone()
  const startDate = data.startsAt
    ? utcToZonedTime(data.startsAt, timezone)
    : null
  const startsAt = data.startsAt
    ? formatInTimeZone(data.startsAt, timezone, 'HH:mm')
    : null
  const endsAt = data.endsAt
    ? formatInTimeZone(data.endsAt, timezone, 'HH:mm')
    : null
  const startTimeOption = startsAt
    ? QUARTER_HOUR_INTERVAL_OPTIONS.find((option) => option.value === startsAt)
    : null
  const endTimeOption = endsAt
    ? QUARTER_HOUR_INTERVAL_OPTIONS.find((option) => option.value === endsAt)
    : null

  return startTimeOption && endTimeOption
    ? {
        selectedDays: includesDate && startDate ? [startDate] : [],
        startTime: startTimeOption as SelectOption,
        endTime: endTimeOption as SelectOption,
        timezone,
        lunchLength: data.lunchLength
          ? (UNPAID_BREAK_OPTIONS.find(
              (option) => option.value === data.lunchLength
            ) as { label: string; value: number })
          : undefined,
      }
    : undefined
}

function getSpecificWorkers(data: ShiftEditData) {
  const workers: CompanyWorker[] = []
  if (data.rosters) {
    for (const roster of data.rosters) {
      if (roster.isSpecificWorkerRequest && roster.workers) {
        workers.push(...roster.workers)
      }
    }
  }

  return workers
}

function getStaff(data: ShiftEditData) {
  const rosters = data.rosters || []
  const isSpecificWorkerRequest = rosters.some(
    (roster) => roster.isSpecificWorkerRequest
  )
  const rosterIds = !isSpecificWorkerRequest
    ? rosters.map((roster) => Number(roster.id))
    : undefined

  return {
    numWorkers: data.workersNeeded ?? 0,
    audienceType: isSpecificWorkerRequest
      ? AudienceType.SpecificWorkers
      : rosterIds && rosterIds.length > 0
      ? AudienceType.Roster
      : AudienceType.Default,
    restrictShiftToAudience: !data.rostersHoldExpiresAt,
    rosterIds,
    specificWorkers: isSpecificWorkerRequest
      ? getSpecificWorkers(data)
      : undefined,
    supervisors:
      data.supervisors?.map((supervisor) => ({
        id: supervisor.userId || Date.now(),
        name: supervisor.name || '',
        phoneNumber: supervisor.phoneNumber,
        requestType: supervisor.userId
          ? SupervisorRequestType.ExistingUser
          : SupervisorRequestType.ExistingShiftSupervisor,
      })) ?? [],
  }
}

function getPayment(data: ShiftEditData) {
  const shiftBonuses = data.shiftBonuses || []
  const bonus = Number(shiftBonuses[shiftBonuses.length - 1]?.amount ?? 0)
  const suggestedBonus = BONUSES.find((b) => b.value === bonus)

  return {
    paymentType: data.payLumpSum
      ? PaymentTypeEnum.LumpSum
      : PaymentTypeEnum.Hourly,
    pay: Number(data.payRate ?? data.payLumpSum ?? 0),
    bonus: suggestedBonus ? suggestedBonus.value : undefined,
    customBonus: !suggestedBonus ? bonus : undefined,
  }
}

export function fromServerData(data: ShiftEditData, includesDate = false) {
  const schemaData: PartialShiftData = {
    details: getDetails(data),
    schedule: getSchedule(data, includesDate),
    staff: getStaff(data),
    payment: getPayment(data),
  }

  return schemaData
}

export function getShiftStatusData(data: ShiftEditData): ShiftStatusData {
  const rosterWorkers = (data.rosters || []).reduce(
    (acc, roster) => [...acc, ...(roster.workers || [])],
    [] as CompanyWorker[]
  )
  const unavailableWorkerIds = (data.unableToAttendWork || [])
    .filter((work) => work.worker)
    .map((work) => Number(work.worker?.id)) as number[]
  const availableWorkerIds = (data.work || [])
    .filter((work) => work.worker)
    .map((work) => Number(work.worker?.id)) as number[]

  const workerStatus: Record<number, WorkerStatus> = {}

  for (const worker of rosterWorkers) {
    const workerId = Number(worker.id)
    workerStatus[workerId] = availableWorkerIds.includes(workerId)
      ? 'available'
      : unavailableWorkerIds.includes(workerId)
      ? 'unavailable'
      : 'pending'
  }

  return {
    workerStatus,
  }
}

export function toServerData(
  data: ShiftData,
  shiftType: ShiftType,
  shiftId?: number
) {
  const timezone = data.schedule.timezone
  const startMinutes = toMinutes(data.schedule.startTime.value)
  const endMinutes = toMinutes(data.schedule.endTime.value)

  const shifts = data.schedule.selectedDays.map((date) => {
    const startsAt = getUTCDateTime(
      date,
      data.schedule.startTime.value,
      timezone
    )
    const endDate = endMinutes < startMinutes ? addDays(date, 1) : date
    const endsAt = getUTCDateTime(
      endDate,
      data.schedule.endTime.value,
      timezone
    )
    const shiftData = {
      starts_at: startsAt.toISOString(),
      ends_at: endsAt.toISOString(),
      workers_needed: data.staff.numWorkers,
      lunch_length: data.schedule.lunchLength?.value,
    }
    return shiftData
  })

  const supervisors = data.staff.supervisors.map((supervisor) => {
    return {
      name: supervisor.name,
      phone_number: unmaskPhoneNumber(supervisor.phoneNumber ?? undefined),
      user_id:
        supervisor.requestType === SupervisorRequestType.ExistingUser
          ? supervisor.id
          : undefined,
      email: supervisor.email,
      role_perm_id: supervisor.roleId ?? undefined,
      request_type: supervisor.requestType,
    }
  })

  const isSmartShift = data.staff.audienceType === AudienceType.Default
  const baseServerData = {
    position_id: data.details.positionId,
    location_id: data.details.locationId,
    locationless: data.details.locationless,
    supervisors,
    pay_rate:
      data.payment.paymentType === PaymentTypeEnum.Hourly
        ? data.payment.pay.toString()
        : undefined,
    pay_lump_sum:
      data.payment.paymentType === PaymentTypeEnum.LumpSum
        ? data.payment.pay.toString()
        : undefined,
    bonus: data.payment.bonus || data.payment.customBonus,
    is_tryout: shiftType === ShiftType.Tryout,
    specific_worker_ids_to_request:
      data.staff.audienceType === AudienceType.SpecificWorkers
        ? (data.staff.specificWorkers || []).map((worker) => {
            const workerData = worker as CompanyWorker
            return workerData.id
          })
        : undefined,
    roster_ids:
      data.staff.audienceType === AudienceType.Roster
        ? data.staff.rosterIds
        : undefined,
    roster_perm_hold: isSmartShift ? false : data.staff.restrictShiftToAudience,
    shift_lead: data.staff.numShiftLeads
      ? {
          shift_leads_needed: data.staff.numShiftLeads,
          early_start_in_minutes:
            data.staff.shiftLeadEarlyStartTime?.value ?? 0,
          later_end_in_minutes: data.staff.shiftLeadLateEndTime?.value ?? 0,
          ...(data.payment.paymentType === PaymentTypeEnum.Hourly
            ? { pay_rate: data.payment.shiftLeadPay }
            : { pay_lump_sum: data.payment.shiftLeadPay }),
          // TODO(siyan): right now backend is capable of handling shift lead bonus to be different
          // from a shift bonus. If we find that not useful in testing, remove this part of the code.
          bonus: data.payment.bonus || data.payment.customBonus,
        }
      : undefined,
  }

  if (shiftId) {
    return {
      ...baseServerData,
      ...shifts[0],
    }
  }

  return {
    ...baseServerData,
    shifts,
  }
}
