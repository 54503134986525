import React from 'react'
import { Box, Heading, Text } from 'ui'
import { useWorkerProfile } from './useWorkerProfile'
import { InfoLineItem } from './InfoLineItem'
import { CarIcon, VerifiedIcon } from 'lucide-react'

export function Certifications() {
  const { profileData } = useWorkerProfile()

  return (
    <Box width={1} py={3}>
      <Heading level={4}>Certifications & Qualifications</Heading>
      {profileData.worker.checkrStatus === 'clear' ? (
        <InfoLineItem
          icon={<VerifiedIcon size={20} />}
          label={'Background Check'}
          value={<Text color={'neutrals.200'}>Verified</Text>}
        />
      ) : null}
      {profileData.worker.workProfile?.hasLicense ? (
        <InfoLineItem
          icon={<VerifiedIcon size={20} />}
          label={'Valid Drivers License'}
          value={<Text color={'neutrals.200'}>Verified</Text>}
        />
      ) : null}
      {profileData.worker.workProfile?.hasCar ? (
        <InfoLineItem
          icon={<CarIcon size={20} />}
          label={'Drives Personal Vehicle'}
          value={<Text color={'neutrals.200'}>&nbsp;</Text>}
        />
      ) : null}
    </Box>
  )
}
