import React, { MutableRefObject, useRef } from 'react'
import {
  AvatarGroup,
  Box,
  Card,
  Flex,
  Heading,
  ProgressBar,
  Text,
  CopyButton,
  Label,
  CardProps,
} from 'ui'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone, getTimezoneAbbr } from 'lib/time'
import { TimelineShift } from 'api/shift'
import styled from 'styled-components'
import { format } from 'date-fns'
import { dateIdFormat } from '../useShiftDates'
import { LucideInfo, RadioIcon } from 'lucide-react'
import { ShiftCtas } from './ShiftCtas'
import { ModalLink } from 'components/ModalLink'
import { LinkProps } from 'react-router-dom'
import { useWorkerStats } from './useWorkerStats'
import { FillStatusText } from './FillStatusText'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'
import { useNeedsApproval } from 'pages/HomePage/ShiftCard/useNeedsApproval'
import { useInViewport } from 'ahooks'
import { BackupShiftAlert } from './BackupShiftAlert'
import { BackupShiftLabel } from 'components/PostBackupShift/BackupShiftLabel'
import { shouldShowClockInOutCodes } from 'lib/shift'

interface Props {
  data: TimelineShift
  translateY?: number
  height?: number
  containerRef?: MutableRefObject<HTMLDivElement | null>
}

const CardWrapper = styled.div.attrs({
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
})``

const CardContainer = styled(Card).attrs<
  LinkProps & CardProps & { $isInactive: boolean }
>({
  width: '100%',
  height: '100%',
  shadowless: true,
  as: ModalLink,
  flexDirection: 'row',
  flexWrap: 'wrap',
  borderWidth: 0,
  color: 'text',
})`
  ${({ theme: { colors }, $isInactive }) => `
    &:hover {
      text-decoration: none;
      color: ${colors.text};
      
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background-color: ${colors.primary};
      }
    }
    
    ${
      $isInactive
        ? `
      .opaque-when-inactive {
        opacity: 0.4;
      }
    `
        : ''
    }
  `}
`

export function ShiftCard(props: Props) {
  const { data, translateY, height } = props
  const timezone = data.location?.address?.timezone || getLocalTimezone()
  const startDate = new Date(data.startsAt)
  const endDate = new Date(data.endsAt)
  const now = new Date()
  const isInProgress = now >= startDate && now <= endDate
  const startsAt = formatInTimeZone(startDate, timezone, 'h:mma')
  const endsAt = formatInTimeZone(endDate, timezone, 'h:mma')
  const { workers, totalWorkers, extraWorkers } = useWorkerStats(data)
  const showNeedsApproval = useNeedsApproval(data) && endDate < now
  const cardRef = useRef<HTMLDivElement | null>(null)
  const [isInViewport] = useInViewport(cardRef, {
    root: props.containerRef,
    rootMargin: '100% 0px',
  })

  return (
    <CardWrapper
      ref={cardRef}
      style={{
        height,
        transform: `translate3d(0, ${translateY}px, 0)`,
      }}
    >
      {!isInViewport ? null : (
        <CardContainer
          $isInactive={endDate < now}
          className={'timeline-shift-card'}
          data-date={format(startDate, dateIdFormat)}
          to={`/shift/${data.id}`}
          onClick={(e) => {
            if (
              e.target instanceof Element &&
              e.target.closest('button, .modal-content')
            ) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        >
          <Box width={[1, 1, 'auto']} flex={['auto', 'auto', 1]}>
            <Flex flexDirection="row">
              <Heading
                className={'opaque-when-inactive'}
                my={0}
                mb={1}
                level={4}
                maxLines={1}
                mr={3}
              >
                {data.position?.name}
              </Heading>
              {data?.isTryout ? (
                <TryoutLabel size="small" hideInfoIcon />
              ) : null}
              {data?.shiftToBackup ? (
                <BackupShiftLabel shift={data} size="small" />
              ) : null}
              {showNeedsApproval ? (
                <Label variant={'error'} size={'small'} flexShrink={0}>
                  Needs Approval
                </Label>
              ) : extraWorkers > 0 ? (
                <Label variant={'information'} size={'small'} flexShrink={0}>
                  {extraWorkers} Extra Workers - on us! &nbsp;&nbsp;
                  <LucideInfo size={12} />
                </Label>
              ) : null}
            </Flex>
            <Box
              className={'opaque-when-inactive'}
              display={'inline-flex'}
              alignItems={'center'}
              color={isInProgress ? 'success' : 'text'}
            >
              {isInProgress ? (
                <>
                  <RadioIcon size={20} />
                  &nbsp;&nbsp;
                </>
              ) : null}
              {startsAt} - {endsAt} {getTimezoneAbbr(timezone, startDate)} -{' '}
              {data.locationless ? 'Locationless' : data.location?.name}
            </Box>
            <Flex alignItems={'center'} mt={3}>
              <Box width={[180, 200, 200, 200, 250]}>
                <AvatarGroup
                  max={5}
                  firstToFront={true}
                  users={totalWorkers.map((w) => ({
                    userId: w.worker?.id.toString(),
                    photoUrl: w.worker?.profilePicUrl,
                    name: w.worker?.name,
                  }))}
                />
              </Box>
              <Box flex={1} className={'opaque-when-inactive'}>
                <FillStatusText shift={data} />
                <ProgressBar
                  mt={2}
                  width={[1, 170]}
                  max={totalWorkers.length}
                  value={workers.length}
                  aria-label={'Shift Filled Progress'}
                />
              </Box>
            </Flex>
          </Box>
          <Flex
            className={'opaque-when-inactive'}
            flexDirection={['row', 'row', 'column']}
            alignItems={'flex-end'}
            justifyContent={['center']}
            width={[1, 200]}
          >
            <Box display={['none', 'none', 'block']}>
              <ShiftCtas data={data} />
            </Box>
            <Box display={['none', 'none', 'block']} flex={1} />
            {shouldShowClockInOutCodes(data) ? (
              <Flex flexDirection={'row'} mt={[3, 3, 0]}>
                {data.clockInCode ? (
                  <Flex
                    flexDirection={'column'}
                    alignItems={'center'}
                    mr={[4, 4, 3]}
                  >
                    <Text color={'lightText'} fontSize={1}>
                      Clock In Code
                    </Text>
                    <Box
                      display={'inline-flex'}
                      alignItems={'center'}
                      fontSize={3}
                    >
                      {data.clockInCode}
                      &nbsp;&nbsp;
                      <CopyButton text={data.clockInCode} />
                    </Box>
                  </Flex>
                ) : null}
                {data.clockOutCode ? (
                  <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text color={'lightText'} fontSize={1}>
                      Clock Out Code
                    </Text>
                    <Box
                      display={'inline-flex'}
                      alignItems={'center'}
                      fontSize={3}
                    >
                      {data.clockOutCode}
                      &nbsp;&nbsp;
                      <CopyButton text={data.clockOutCode} />
                    </Box>
                  </Flex>
                ) : null}
              </Flex>
            ) : null}
          </Flex>
          <Box
            className={'opaque-when-inactive'}
            display={['block', 'block', 'none']}
            mt={3}
            mx={'auto'}
          >
            <ShiftCtas data={data} />
          </Box>
          <BackupShiftAlert shift={data} />
        </CardContainer>
      )}
    </CardWrapper>
  )
}
