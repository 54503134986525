import { PaginatedShiftTimeline, TimelineShift } from 'api/shift'
import { useEffect, useState } from 'react'
import { addDays, endOfDay, format, startOfDay } from 'date-fns'
import { useHomeContext } from './useHomeContext'
import { InfiniteData } from '@tanstack/react-query'
import { useResponsiveValue } from '@workwhile/ui'

export const dateIdFormat = 'yyyy-MM-dd'
export const headerHeight = 60
export const scrollHeightLimit = 50000
const daysIncrement = 50
const contentMargin = 15

export enum TimelineContentType {
  ShiftCard = 'ShiftCard',
  ShiftCardPlaceholder = 'ShiftCardPlaceholder',
  DateHeader = 'DateHeader',
  LoadPrevious = 'LoadPrevious',
  LoadNext = 'LoadNext',
}

type TimelineBaseContent = {
  id: string
  translateY: number
  height: number
}

export type TimelineShiftCardPlaceholder = TimelineBaseContent & {
  type: TimelineContentType.ShiftCardPlaceholder
}

export type TimelineShiftCard = TimelineBaseContent & {
  type: TimelineContentType.ShiftCard
  data: TimelineShift
}

export type TimelineDateHeader = TimelineBaseContent & {
  type: TimelineContentType.DateHeader
  data: Date
  hasContent: boolean
  isFooter: boolean
}

type TimelineLoadPrevious = TimelineBaseContent & {
  type: TimelineContentType.LoadPrevious
}

type TimelineLoadNext = TimelineBaseContent & {
  type: TimelineContentType.LoadNext
}

export type TimelineContent =
  | TimelineShiftCard
  | TimelineShiftCardPlaceholder
  | TimelineDateHeader
  | TimelineLoadNext
  | TimelineLoadPrevious

interface Props {
  data?: InfiniteData<PaginatedShiftTimeline>
  hasNextPage?: boolean
  hasPreviousPage?: boolean
}

/* Compute additional height to add to the default card height
 * to account for additional content in the card
 * @param {TimelineShift} shift The shift to compute the additional height for
 * @returns {number[]} Responsive values for the additional height
 * i.e. [0, 10, 20] means 0px additional height for mobile, 10px for tablet, 20px for desktop
 * i.e. [0, 10] means 0px additional height for mobile, 10px for tablet and desktop
 * i.e. [0] means 0px additional height for mobile, tablet and desktop
 * i.e. undefined means no additional height needed
 */
function computeAdditionalCardHeight(
  shift: TimelineShift // eslint-disable-line @typescript-eslint/no-unused-vars
): number[] | undefined {
  // add whatever logic you need to compute the additional height required
  if (shift.recommendBackupShift) {
    return [200, 150, 150, 70]
  }
}

export function useTimelineContent(props: Props) {
  const { data, hasNextPage } = props
  const { renderDate: referenceDate } = useHomeContext()
  const [lowerBound, setLowerBound] = useState<Date | null>(null)
  const [upperBound, setUpperBound] = useState<Date | null>(null)
  const [allShifts, setAllShifts] = useState<TimelineShift[] | null>(null)
  const [timelineContent, setTimelineContent] = useState<TimelineContent[]>([])
  const cardHeight = useResponsiveValue([245, 195, 140])
  const responsiveIndex = useResponsiveValue([0, 1, 2, 3])
  const [startingY, setStartingY] = useState(scrollHeightLimit)

  useEffect(() => {
    if (!data || !referenceDate) return

    const shifts = data.pages
      .map((page) => {
        return page ? page.items : []
      })
      .flat()
    const firstDate = startOfDay(
      shifts.length > 0 ? new Date(shifts[0].startsAt) : referenceDate
    )
    const lastDate = endOfDay(
      shifts.length > 0
        ? new Date(shifts[shifts.length - 1].startsAt)
        : referenceDate
    )

    setLowerBound((prevBound) => {
      if (prevBound) {
        return prevBound < firstDate ? prevBound : firstDate
      }
      return firstDate < referenceDate ? firstDate : referenceDate
    })

    setUpperBound((prevBound) => {
      if (prevBound) {
        return prevBound > lastDate ? prevBound : lastDate
      }
      if (hasNextPage) {
        return lastDate > referenceDate ? lastDate : referenceDate
      }
      return addDays(lastDate, daysIncrement)
    })

    setAllShifts(shifts)
  }, [referenceDate, data?.pages, hasNextPage])

  useEffect(() => {
    if (!lowerBound || !upperBound || !allShifts || !referenceDate) return

    const content: TimelineContent[] = []

    const getCardHeight = (shift: TimelineShift) => {
      const additionalHeight = computeAdditionalCardHeight(shift)
      if (!additionalHeight) return cardHeight
      const responsiveValue =
        additionalHeight[responsiveIndex] ??
        additionalHeight[additionalHeight.length - 1]
      return responsiveValue + cardHeight
    }

    // starting from reference_date - 1, going into the past
    let curDate = addDays(referenceDate, -1)
    let currentY = startingY
    while (curDate >= lowerBound) {
      const dateId = format(curDate, dateIdFormat)
      const dateShifts = allShifts
        .filter(
          (shift) => format(new Date(shift.startsAt), dateIdFormat) === dateId
        )
        .reverse()
      const headerContent = {
        id: dateId,
        data: curDate,
        height: headerHeight,
        hasContent: dateShifts.length > 0,
        isFooter: false,
      }
      content.push({
        ...headerContent,
        id: `${dateId}-footer`,
        type: TimelineContentType.DateHeader,
        translateY: currentY - contentMargin - headerHeight,
        isFooter: true,
      })
      dateShifts.forEach((shift) => {
        const dynamicCardHeight = getCardHeight(shift)
        currentY -= contentMargin + dynamicCardHeight
        content.push({
          id: shift.id.toString(),
          type: TimelineContentType.ShiftCard,
          data: shift,
          translateY: currentY,
          height: dynamicCardHeight,
        })
      })
      currentY -= headerHeight + contentMargin
      content.push({
        ...headerContent,
        type: TimelineContentType.DateHeader,
        translateY: currentY,
        isFooter: false,
      })
      curDate = addDays(curDate, -1)
    }
    content.push({
      id: 'load-previous',
      type: TimelineContentType.LoadPrevious,
      translateY: currentY,
      height: headerHeight,
    })

    // insert some placeholders
    new Array(20).fill(0).forEach((_, index) => {
      currentY -= cardHeight + contentMargin
      content.push({
        id: 'placeholder-past-' + index,
        type: TimelineContentType.ShiftCardPlaceholder,
        translateY: currentY,
        height: cardHeight,
      })
    })

    // starting from reference_date, going into the future
    curDate = referenceDate
    currentY = startingY
    while (curDate <= upperBound) {
      const dateId = format(curDate, dateIdFormat)
      const dateShifts = allShifts.filter(
        (shift) => format(new Date(shift.startsAt), dateIdFormat) === dateId
      )
      const headerContent = {
        id: dateId,
        data: curDate,
        height: headerHeight,
        hasContent: dateShifts.length > 0,
      }
      content.push({
        ...headerContent,
        type: TimelineContentType.DateHeader,
        translateY: currentY,
        isFooter: false,
      })
      currentY += headerHeight + contentMargin
      dateShifts.forEach((shift) => {
        const dynamicCardHeight = getCardHeight(shift)
        content.push({
          id: shift.id.toString(),
          type: TimelineContentType.ShiftCard,
          data: shift,
          translateY: currentY,
          height: dynamicCardHeight,
        })
        currentY += dynamicCardHeight + contentMargin
      })
      content.push({
        ...headerContent,
        id: `${dateId}-footer`,
        type: TimelineContentType.DateHeader,
        translateY: currentY - contentMargin - headerHeight,
        isFooter: true,
      })
      curDate = addDays(curDate, 1)
    }

    content.push({
      id: 'load-next',
      type: TimelineContentType.LoadNext,
      translateY: currentY,
      height: headerHeight,
    })

    setTimelineContent(content)
  }, [
    referenceDate,
    lowerBound,
    upperBound,
    allShifts,
    cardHeight,
    startingY,
    responsiveIndex,
  ])

  return {
    startingY,
    allShifts,
    timelineContent,
    upperBound,
    lowerBound,
    increaseLowerBound: () => {
      if (referenceDate) {
        setLowerBound(addDays(lowerBound ?? referenceDate, -daysIncrement))
      }
    },
    increaseUpperBound: () => {
      if (referenceDate) {
        setUpperBound(addDays(upperBound ?? referenceDate, daysIncrement))
      }
    },
    increaseStartingY: () => setStartingY(startingY + scrollHeightLimit),
  }
}
