import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  DatePicker,
  Field,
  Flex,
  Heading,
  Message,
  Select,
  Text,
} from 'ui'
import { track } from 'lib/amplitude'
import { useShiftEditor } from '../../useShiftEditor'
import { Controller, useForm } from 'react-hook-form'
import { OrientationShiftForm, orientationShiftSchema } from '../../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { TimeSelector } from 'components/TimeSelector'
import { SelectableDuration } from 'typings/selectable_ext'
import {
  getLocalTimezone,
  getLongDuration,
  getTimezoneName,
  getW2BreakOptions,
  UNPAID_BREAK_OPTIONS,
} from 'lib/time'
import { useCompanyLocation } from 'hooks/useCompanyLocation'
import { FiClock } from 'react-icons/fi'
import { Footer } from '../../Footer'
import { useShiftTime } from '../../useShiftTime'
import { EditorMode } from '../../ShiftEditorProvider'
import { addWeeks } from 'date-fns'
import { useAuthContext } from 'components/auth'
import { useLunchLabel } from '../../useLunchLabel'
import { useUpdateEffect } from 'ahooks'
import { useW2MinimumLunchLength } from 'queries/company/useW2MinimumLunchLength'

export function OrientationShiftPage() {
  const { shiftData, editorMode, setShiftData, goNext } = useShiftEditor()
  const { company } = useAuthContext()
  const isPastShift = editorMode === EditorMode.PastShift

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<OrientationShiftForm>({
    resolver: zodResolver(orientationShiftSchema),
    defaultValues: {
      isW2: company?.w2 === true,
      ...shiftData?.orientationShift,
    } as OrientationShiftForm,
  })

  const selectedLocation = useCompanyLocation(shiftData?.details?.locationId)
  const selectedDays = watch('selectedDays')
  const startTime = watch('startTime')
  const endTime = watch('endTime')
  const willHaveOrientation = watch('willHaveOrientation')
  const lunchLength = watch('lunchLength')
  const isW2 = watch('isW2')
  const timezone = selectedLocation?.address?.timezone ?? getLocalTimezone()
  const {
    isOvernight,
    spansDaylightSaving,
    totalDurationInMinutes,
    startsAt,
    endsAt,
  } = useShiftTime({
    date: selectedDays?.[0],
    startTime: startTime?.value,
    endTime: endTime?.value,
    lunchLength: lunchLength?.value,
    timezone,
  })

  const { data: minimumLunchLength = 0 } = useW2MinimumLunchLength({
    locationId: selectedLocation?.id,
    startTime: startsAt ?? undefined,
    endTime: endsAt ?? undefined,
  })

  const breakOptions = useMemo(
    () => (isW2 ? getW2BreakOptions(minimumLunchLength) : UNPAID_BREAK_OPTIONS),
    [isW2, minimumLunchLength]
  )

  const lunchLabel = useLunchLabel({
    isW2: isW2 ?? false,
    minimumLunchLength,
  })

  const onSubmit = handleSubmit((data) => {
    setShiftData({
      orientationShift: {
        ...data,
        timezone,
      },
    })
    goNext()
  })

  useEffect(() => {
    track('impression, ShiftEditor_Dates_page')
  }, [])

  useUpdateEffect(() => {
    if (isW2 && breakOptions.length > 0) {
      setValue('lunchLength', breakOptions[0] as Required<SelectableDuration>)
    }
  }, [isW2, breakOptions])

  return (
    <Box>
      <Heading level={[2, 1]} mt={4} mb={0}>
        Orientation shift
      </Heading>
      <Text color="lightText">
        Orientation shifts are used to schedule orientation for new workers.
      </Text>

      <Box width={[1, 1, '65rem']}>
        <form onSubmit={onSubmit}>
          <Field
            mt={3}
            label={
              <Flex alignItems="center">
                <Text mr={2} style={{ flex: 1 }}>
                  Will you have an Orientation shift?
                </Text>
              </Flex>
            }
            error={errors.willHaveOrientation?.message?.toString()}
          >
            <Controller
              name={'willHaveOrientation'}
              control={control}
              render={({ field }) => (
                <Select
                  aria-label={'Select'}
                  placeholder={'Select'}
                  value={field.value}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  onChange={(option) => {
                    field.onChange(option)
                  }}
                />
              )}
            />
          </Field>

          {willHaveOrientation?.value ? (
            <>
              <Field
                label={
                  <Heading level={3} my={1}>
                    {isPastShift
                      ? 'Select the Date the Shift occurred'
                      : 'Select Date'}
                  </Heading>
                }
                error={errors.selectedDays?.message?.toString()}
                mb={4}
              >
                <Controller
                  name={'selectedDays'}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      mode="multiple"
                      disabled={
                        isPastShift
                          ? {
                              after: new Date(),
                              before: addWeeks(new Date(), -3),
                            }
                          : {
                              before: new Date(),
                            }
                      }
                      selected={field.value as Date[] | undefined}
                      onSelect={(days) =>
                        field.onChange(days instanceof Date ? [days] : days)
                      }
                      placeholder={'Select Date - You can select multiple days'}
                    />
                  )}
                />
              </Field>
              <Heading level={3}>Select Time</Heading>
              <Flex flexDirection={['column', 'row']}>
                <Field
                  width={[1, 1 / 2]}
                  mr={[0, 2]}
                  label={'Start time'}
                  error={errors.startTime?.message?.toString()}
                >
                  <Controller
                    name={'startTime'}
                    control={control}
                    render={({ field }) => (
                      <TimeSelector
                        aria-label={'Select Start Time'}
                        placeholder={'Select a start time'}
                        value={field.value}
                        from={'07:00'}
                        date={selectedDays?.[0]}
                        timezone={timezone}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Field>
                <Field
                  width={[1, 1 / 2]}
                  ml={[0, 2]}
                  label={'End time'}
                  error={errors.endTime?.message?.toString()}
                >
                  <Controller
                    name={'endTime'}
                    control={control}
                    render={({ field }) => (
                      <TimeSelector
                        aria-label={'Select End Time'}
                        placeholder={'Select an end time'}
                        value={field.value}
                        from={startTime?.value}
                        date={selectedDays?.[0]}
                        timezone={timezone}
                        showDuration={true}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Field>
              </Flex>
              <Message hideIcon={true} variant={'fixed'} systemLevel={false}>
                <Flex alignItems={'center'} flexDirection={'row'}>
                  <FiClock />
                  <Box ml={2}>
                    Time zone for this shift is{' '}
                    {getTimezoneName(timezone, selectedDays?.[0])}
                  </Box>
                </Flex>
              </Message>
              {isOvernight && totalDurationInMinutes ? (
                <Message
                  variant={'warning'}
                  systemLevel={false}
                  mt={3}
                  width={'100%'}
                >
                  This shift is currently scheduled for{' '}
                  <strong>{getLongDuration(totalDurationInMinutes)}</strong>{' '}
                  (excluding unpaid breaks). If this is incorrect, please
                  correct the Start or End time.
                </Message>
              ) : null}
              {spansDaylightSaving ? (
                <Message
                  variant={'warning'}
                  systemLevel={false}
                  mt={3}
                  width={'100%'}
                >
                  This shift spans a daylight saving time change. Please double
                  check the Start and End times.
                </Message>
              ) : null}

              <Field
                mt={3}
                label={
                  <Flex alignItems="center">
                    <Text fontWeight="bold" mr={2} style={{ flex: 1 }}>
                      {lunchLabel}
                    </Text>
                    {isW2 ? (
                      <Button
                        variant="text"
                        size="small"
                        as="a"
                        href="https://www.dol.gov/agencies/whd/state/meal-breaks"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        More info
                      </Button>
                    ) : null}
                  </Flex>
                }
                error={errors.lunchLength?.message?.toString()}
              >
                <Controller
                  name={'lunchLength'}
                  control={control}
                  render={({ field }) => (
                    <Select
                      aria-label={'Select Break Time'}
                      placeholder={'Select a break time'}
                      value={field.value}
                      options={breakOptions}
                      onChange={(option) => {
                        field.onChange(option)
                      }}
                    />
                  )}
                />
              </Field>
            </>
          ) : null}

          <Footer />
        </form>
      </Box>
    </Box>
  )
}
