import { useQuery } from '@tanstack/react-query'
import { WorkerAvailabilityRequest, getWorkerAvailability } from 'api/worker'
import { workerAvailabilityKey } from './keys'
import { useAuthContext } from 'components/auth'

export function useWorkerAvailability({
  workerIds,
  hypotheticalShifts,
  shiftIds,
}: WorkerAvailabilityRequest) {
  const { company } = useAuthContext()
  return useQuery({
    queryKey: workerAvailabilityKey({
      workerIds,
      hypotheticalShifts,
      shiftIds,
    }),
    queryFn: () =>
      getWorkerAvailability({
        companyId: company?.id || 0,
        workerIds,
        hypotheticalShifts,
        shiftIds,
      }),
    staleTime: 2 * 60 * 1000, // 2 minute stale time
  })
}
