import { graphql } from '../graphql'
import { path } from 'ramda'
import { EmployerGuidance } from 'typings/common_defs'

export interface EmployerGuidanceOptions {
  positionId: number
  locationId: number
  mileage?: number
  shiftDuration?: number
}

export async function getEmployerGuidance(options: EmployerGuidanceOptions) {
  const { positionId, locationId, mileage, shiftDuration } = options
  const result = await graphql(
    `company {
          employerGuidance(
            positionId: ${positionId},
            locationId: ${locationId},
            ${mileage ? `mileage: ${mileage},` : ''}
            shiftDurationHours: ${shiftDuration}
          ) 
          {
            payEstimate
          }
        }`
  )

  return path(
    ['data', 'data', 'company', 'employerGuidance'],
    result
  ) as EmployerGuidance
}
