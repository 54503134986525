import React, { useMemo } from 'react'
import {
  Box,
  Button,
  Heading,
  Flex,
  Modal,
  Text,
  Table,
  createColumnHelper,
  Message,
} from 'ui'
import { User } from 'typings/common_defs'
import { RSWCard } from './RSWCard'

interface Props {
  workers: User[]
  onConfirm: () => void
  onCancel: () => void
  open: boolean
  setValue: (name: string, value: any) => void
  getValues: (name?: string | string[]) => any
}

const columnHelper = createColumnHelper<User>()

export function DoubleConfirmationModal({
  workers,
  onConfirm,
  onCancel,
  open,
  setValue,
  getValues,
}: Props) {
  const handleRemoveWorker = (workerId: number) => {
    const currentWorkers = getValues('specificWorkers') || []
    const updatedWorkers = currentWorkers.filter((w) => w.id !== workerId)
    setValue('specificWorkers', updatedWorkers)
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (info) => (
          <RSWCard
            data={info.row.original}
            onRemove={() => handleRemoveWorker(info.row.original.id)}
          />
        ),
      }),
    ],
    [handleRemoveWorker]
  )

  return (
    <Modal
      open={open}
      onClose={onCancel}
      width={[1, 1, 800, 900]}
      maxWidth="auto"
    >
      <Box p={4}>
        <Heading level={2}>Confirm Worker Selection</Heading>
        <Text mb={3}>
          The following workers are unavailable. Are you sure you want to
          proceed?
        </Text>
        <Box mb={3} overflowX="auto">
          {workers.length > 0 ? (
            <Table columns={columns} data={workers} />
          ) : (
            <Message variant="warning">
              No workers left to display. Please add workers before proceeding.
            </Message>
          )}
        </Box>
        <Flex justifyContent="flex-end">
          <Button onClick={onCancel} variant="secondary" mr={2}>
            Cancel
          </Button>
          <Button onClick={onConfirm} disabled={workers.length === 0}>
            Confirm
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}
