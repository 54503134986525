const alerts = {
  success50: "#E1FFEE",
  success100: "#00B64C",
  success200: "#00642A",
  info50: "#E0EFFF",
  info100: "#1A87FF",
  info200: "#0052AE",
  warning50: "#FFEDD0",
  warning100: "#FFAD28",
  warning200: "#7F4F00",
  error50: "#FFEEEB",
  error100: "#ED4E2B",
  error200: "#9B270D",
};

export const newColors = {
  // new colors
  alerts,
  // legacy colors
  primary: "#27C8B6",
  primaryMedium: "#8AE6DC",
  primaryLight: "#D6ECE9",
  primaryLightest: "#F4FFFE",
  primaryTransparent: "rgba(39,200,182, 0.2)",
  white: "#FFFFFF",
  altWhite: "#fafafa",
  black: "#000000",
  veryLightBlack: "rgba(0, 0, 0, 0.1)",
  lighterBlack: "#222222",
  blackWithTransparency: "rgba(0,0,0, 0.5)",
  whiteWithTransparency: "rgba(255,255,255, 0.5)",
  lightGray: "#F3F3F3",
  gray: "#E1E1E1",
  black30: "rgba(0,0,0, 0.4)",
  error: "#ED4E2B",
  wwErrorLight: "#FCEDEA",
  warning: "#EF9400",
  wwWarningLight: "#FEF7EA",
  info: "#006DE6",
  infoLight: "#E5EFFA",
  primaryGradient: ["#27A1C8", "#27C8B6"],
  transparentGradient: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
  baseNeutral: "#FDFEFF",
  transparent: "rgba(255,255,255,0)",
};

export const newDimens = {
  xsmall: 8,
  small: 15,
  standard: 20,
  large: 25,
  xlarge: 55,
  borderRadiusStandard: 4,
};
