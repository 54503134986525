import React from 'react'
import { useShiftEditor } from '../../useShiftEditor'
import { useEmployerGuidance } from 'queries/shift'
import { useFormContext } from 'react-hook-form'
import { PaymentForm, PaymentTypeEnum } from '../../schemas'
import { Box, Text, Message, Flex } from 'ui'
import { CircleDollarSign } from 'lucide-react'
import { useTheme } from 'styled-components'
import { useShiftTime } from 'pages/ShiftEditorPage/useShiftTime'

export function PayGuidance() {
  const { shiftData } = useShiftEditor()
  const { durationInMinutesMinusLunch } = useShiftTime({
    date: shiftData.schedule?.selectedDays[0],
    startTime: shiftData.schedule?.startTime?.value,
    endTime: shiftData.schedule?.endTime?.value,
    lunchLength: shiftData.schedule?.lunchLength?.value,
    timezone: shiftData.schedule?.timezone,
  })

  const {
    durationInMinutesMinusLunch: durationInMinutesMinusLunchOrientation,
  } = useShiftTime({
    date: shiftData.orientationShift?.selectedDays?.[0],
    startTime: shiftData.orientationShift?.startTime?.value,
    endTime: shiftData.orientationShift?.endTime?.value,
    lunchLength: shiftData.orientationShift?.lunchLength?.value,
    timezone: shiftData.orientationShift?.timezone,
  })

  const shiftDuration =
    (durationInMinutesMinusLunch || 0) +
    (durationInMinutesMinusLunchOrientation || 0)

  const { data: employerGuidance } = useEmployerGuidance({
    positionId: shiftData?.details?.positionId as number,
    locationId: shiftData?.details?.locationId as number,
    mileage: shiftData?.details?.expectedMileage,
    shiftDuration: shiftDuration,
  })
  const { colors } = useTheme()
  const { watch } = useFormContext<PaymentForm>()
  const payType = watch('paymentType')
  const numShiftLeads = shiftData.staff?.numShiftLeads

  if (!employerGuidance || !employerGuidance.payEstimate) {
    return null
  }

  if (payType === PaymentTypeEnum.LumpSum) {
    return null
  }

  return (
    <Message variant={'fixed'} hideIcon={true}>
      <Flex>
        <Box flexShrink={0} pr={2}>
          <CircleDollarSign size={24} color={colors.primary} />
        </Box>
        <Box>
          <Text fontWeight={2}>Recommended Pay</Text>
          <Box mt={1}>
            {employerGuidance.payEstimate}
            {numShiftLeads
              ? ' And we recommend paying shift leads 20% higher.'
              : null}
          </Box>
        </Box>
      </Flex>
    </Message>
  )
}
