import * as zod from 'zod'

export const detailsSchema = zod.object({
  assignmentName: zod
    .string()
    .min(1, {
      message: 'Please enter an Assignment Name',
    })
    .optional(),
  locationId: zod.number({
    required_error: 'Please select a Location',
  }),
  positionId: zod.number({
    required_error: 'Please select a Position',
  }),
  expectedMileage: zod.coerce.number().optional(),
  locationless: zod.boolean().default(false),
  acknowledgeTryoutList: zod
    .literal(true, {
      errorMap: () => ({
        message: "Please check the checkbox in the 'Acknowledge List' section.",
      }),
    })
    .optional(),
})

export type DetailsForm = zod.infer<typeof detailsSchema>
