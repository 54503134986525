import { useShiftEditor } from '../useShiftEditor'
import { useMemo } from 'react'
import { PaymentTypeEnum } from '../schemas'
import { useShiftSummary } from '../useShiftSummary'
import { useShiftTime } from '../useShiftTime'

export function usePreviewData() {
  const { shiftData } = useShiftEditor()
  const { dates, time, orientationShift } = useShiftSummary()
  const earliestDateBetweenOrientationAndWork = useMemo(() => {
    const orientationDate = orientationShift?.dates?.[0]
    const workDate = dates?.[0]
    if (!orientationDate) return workDate
    if (!workDate) return orientationDate
    return orientationDate < workDate ? orientationDate : workDate
  }, [orientationShift, dates])

  const { durationInMinutesMinusLunch: durationInMinutesMinusLunchWork } =
    useShiftTime({
      date: earliestDateBetweenOrientationAndWork,
      startTime: shiftData.schedule?.startTime?.value,
      endTime: shiftData.schedule?.endTime?.value,
      lunchLength: shiftData.schedule?.lunchLength?.value,
      timezone: shiftData.schedule?.timezone,
    })

  const {
    durationInMinutesMinusLunch: durationInMinutesMinusLunchOrientation,
  } = useShiftTime({
    date: earliestDateBetweenOrientationAndWork,
    startTime: shiftData.orientationShift?.startTime?.value,
    endTime: shiftData.orientationShift?.endTime?.value,
    lunchLength: shiftData.orientationShift?.lunchLength?.value,
    timezone: shiftData.orientationShift?.timezone,
  })

  const durationInMinutesMinusLunch =
    (durationInMinutesMinusLunchWork || 0) +
    (durationInMinutesMinusLunchOrientation || 0)

  const estimatedPay = useMemo(() => {
    const pay = shiftData.payment?.pay ?? 0
    const isHourly = shiftData.payment?.paymentType === PaymentTypeEnum.Hourly
    const bonus =
      shiftData.payment?.bonus || shiftData.payment?.customBonus || 0
    if (isHourly) {
      const totalHours = durationInMinutesMinusLunch
        ? durationInMinutesMinusLunch / 60
        : 0
      return pay * totalHours + bonus
    }

    return pay + bonus
  }, [durationInMinutesMinusLunch, shiftData])

  return {
    estimatedPay,
    date: earliestDateBetweenOrientationAndWork,
    time,
  }
}
