import { Location } from 'react-router-dom'
import { unstable_useBlocker as useBlocker } from 'react-router'
import { Action as HistoryAction } from 'history'
import { useModal } from 'ui'
import { useState } from 'react'
import { useShiftEditor } from './useShiftEditor'
import { useNavigateWithContext } from './useNavigateWithContext'

export function useUnsavedBlock() {
  const { showModal } = useModal()
  const { navigate } = useNavigateWithContext()
  const [unblocked, setUnblocked] = useState(false)
  const { isDirty } = useShiftEditor()

  useBlocker(
    (props: {
      currentLocation: Location
      nextLocation: Location
      historyAction: HistoryAction
    }) => {
      const { nextLocation } = props
      if (unblocked || !isDirty) {
        return false
      }

      if (!nextLocation.pathname.includes('/home/shift-editor')) {
        const { closeModal } = showModal({
          title: 'You have unsaved changes',
          content: 'Are you sure you want to leave this page?',
          okText: 'Leave',
          onOk: () => {
            setUnblocked(true)
            setTimeout(() => {
              closeModal()
              navigate(nextLocation, {}, true)
            }, 0)
          },
        })
        return true
      }
      return false
    }
  )
}
