import { Assignment } from 'typings/common_defs'

type GetAssignmentDetailRequest = {
  assignmentId?: number
}

type GetAssignmentDetailResponse = Assignment

export const getAssignmentDetail = async ({
  //TODO: start using this once backend is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assignmentId,
}: GetAssignmentDetailRequest) => {
  // FIXME: Add this back once backend is ready
  //   const data = await api.get(`/assignments/${assignmentId}`)
  //   return data.data as GetAssignmentDetailResponse

  return new Promise<GetAssignmentDetailResponse>((resolve) => {
    setTimeout(() => {
      resolve({
        id: 1,
        name: 'A Fictional Assignment',
      } as Assignment)
    }, 2000)
  })
}
