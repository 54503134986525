import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { TimelineShift } from 'api/shift'

export async function getCompanyShiftDetail({
  ids,
  includeEstimate = false,
  includePositionLocationDebut = false,
}: {
  ids: number[]
  includeEstimate?: boolean
  includePositionLocationDebut?: boolean
}) {
  const data = await graphql(
    `company {
        shiftsDetail(ids: [${ids}], includeEstimate: ${includeEstimate}) {
          clockInCode, clockOutCode,
          requiresClockInCode, requiresClockOutCode,
          requiresQrClockInCode, requiresQrClockOutCode,
          bundleMetadata {
            bundleIndex,
            bundleSize
          }
          recommendBackupShift
          shiftToBackup {
            startsAt
          }
          rosters { 
            id, name, 
            workers {
              id, name, profilePicUrl
            } 
            isSpecificWorkerRequest
          }
          rostersHoldExpiresAt
          shiftEndPaymentDelayHours
          shiftBonuses { amount },
          position {
            name, id, hidden
            roster {
              id, name
              rosterUsers {
                userId, status
              }
            }
          }
          location { name, id, address {city, state, street, unit, zip, lat, long, timezone}}
          locationless
          leadShifts {
            id,
            startsAt,
            endsAt,
            shiftBonuses { amount },
            position { name, id},
            location { name, id, address {city, state, street, unit, zip, lat, long, timezone}}
            locationless
            shiftType, supervisor, supervisorPhone, supervisors (status: "scheduled") { id, name, phoneNumber, userId, status}, workersNeeded, 
            chargeLumpSum, chargeRate, payRate, payLumpSum, lunchLength
            work {
              id, status, confirmedAt
              startedAt, completedAt
              createdAt
              employerApprovedAt
              pendingAdminReview
              reservationExpiresAt
              reservationNotifiedAt
              status
              trips {status, shareUrl, eta, etaSeconds, locationStatus, serviceDeviceId}
              worker {
                id, name, phoneNumber, profilePicUrl
              }
            }
          },
          isTryout,
          id, startsAt, endsAt, shiftType, supervisor, supervisorPhone, supervisors (status: "scheduled") { id, name, phoneNumber, userId, status}, workersNeeded, 
          chargeLumpSum, chargeRate, payRate, payLumpSum, lunchLength,
          cancellationPolicy {
            policyWindows {
              cutoffTimeHours
              chargePercentage
            }
          }
          minimumPayPolicy{
            minPayLength
          }
          ${includeEstimate ? 'totalEstimatedCharge' : ''},
          work {
            id,
            isAvailable,
            ${includePositionLocationDebut ? 'isPositionLocationDebut' : ''}
            leftEarly,
            worker {
              id, name, profilePicUrl
            }
            confirmedAt
            earnings
            employerApprovedAt
            finishedEarlyCategory
            lunchLengthOverride
            supervisorReasonEarlyClockOut,
            supervisorMarkedNoShow,
            startedAt, completedAt
            createdAt
            pendingAdminReview
            reservationExpiresAt
            reservationNotifiedAt
            status
            timeWorkedSeconds
            trips {status, shareUrl, eta, etaSeconds, locationStatus, serviceDeviceId}
          },
          unableToAttendWork {
            id
            status
            worker { id }
          }
        }
      }`,
    'shift_detail'
  )

  const shifts = path(
    ['data', 'data', 'company', 'shiftsDetail'],
    data
  ) as TimelineShift[]
  return shifts.map((shift) => ({
    ...shift,
    id: Number(shift.id),
  }))
}
