import { getDemoData, getGraphqlRequest, matchGraphqlRequest } from '../utils'
import { addDays, addYears, differenceInHours } from 'date-fns'
import { CompanyWorker } from 'api/worker'
import { DemoCategory } from 'api/demo/types'
import { DEMO_COMPANY_ID } from 'api/demo'

export const mockGetWorkerProfile = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /name,worker\(workerId/),
  getResponse: async (request) => {
    const graphqlRequest = getGraphqlRequest(request)
    const demoData = getDemoData()
    const workerId = Number(graphqlRequest.variables.workerId)
    const worker = demoData.workers.find(
      (worker) => worker.id === workerId
    ) as CompanyWorker

    const allWork = demoData.shifts
      .map((shift) =>
        (shift.work ?? []).map((work) => ({
          ...work,
          shift,
        }))
      )
      .flat()
    const workForCompany = allWork.filter(
      (work) => work.worker?.id === workerId
    )
    let totalHours = 0
    let totalHoursPastWeek = 0
    const pastWeekRef = addDays(new Date(), -7)
    workForCompany.forEach((work) => {
      const shiftStart = new Date(work.shift.startsAt!)
      const shiftEnd = new Date(work.shift.endsAt!)
      const shiftHours = differenceInHours(
        new Date(shiftEnd),
        new Date(shiftStart)
      )
      totalHours += shiftHours
      if (shiftStart > pastWeekRef) {
        totalHoursPastWeek += shiftHours
      }
    })

    return {
      data: {
        company: {
          id: DEMO_COMPANY_ID,
          name: 'DemoCo',
          worker: {
            ...worker,
            checkrStatus: 'clear',
            favorites: Math.max(Math.floor(workForCompany.length / 4), 1),
            firstActive: addDays(new Date(), -200).toISOString(),
            numFinishedShifts: workForCompany.length,
            numHoursForCompany: totalHours,
            numHoursForCompanyPastWeek: totalHoursPastWeek,
            address: {
              city: 'San Francisco',
              state: 'CA',
            },
            workProfile: {
              hasCar: true,
              hasLicense: true,
              workExperience: generateWorkExperience(demoData.category)
                .map((experience, index) => ({
                  ...experience,
                  startedAt: addYears(new Date(), -(index + 2)).toISOString(),
                  endedAt: addYears(new Date(), -(index + 1)).toISOString(),
                }))
                .reverse(),
            },
            workForCompany,
          },
        },
      },
    }
  },
}

function generateWorkExperience(category: DemoCategory) {
  switch (category) {
    case 'warehouse':
      return [
        {
          id: 1,
          companyName: 'ABC Manufacturing',
          experience: 'Forklift Operator',
          skills: JSON.stringify(['Forklift', 'Pallet Jack']),
        },
        {
          id: 2,
          companyName: 'XYZ Solutions',
          experience: 'Warehouse Associate',
          skills: JSON.stringify([
            'Receiving',
            'Picking & Packing',
            'RF Scanner',
          ]),
        },
      ]
    case 'hospitality':
      return [
        {
          id: 1,
          companyName: 'ABC Event Staffing',
          experience: 'Event Staff',
          skills: JSON.stringify([
            'Bartending',
            'Setup & Teardown',
            'Waitstaff',
          ]),
        },
        {
          id: 2,
          companyName: 'XYZ Fast Food',
          experience: 'Food Handler',
          skills: JSON.stringify([
            'California Food Handlers Card',
            'Cook',
            'Dishwasher',
            'Food production',
          ]),
        },
      ]
    case 'driving':
      return [
        {
          id: 1,
          companyName: 'ABC Delivery Services',
          experience: 'Delivery Driver',
          skills: JSON.stringify([
            'Using delivery management app, such as Onfleet',
            'Customer service',
          ]),
        },
        {
          id: 2,
          companyName: 'XYZ Solutions',
          experience: 'Delivery Driver',
          skills: JSON.stringify(['Delivering Large Parcels']),
        },
        {
          id: 3,
          companyName: 'Papa Johns',
          experience: 'Delivery Driver',
          skills: JSON.stringify([
            'Commercial drivers license',
            'Customer service',
          ]),
        },
      ]
    default:
      return [
        {
          id: 1,
          companyName: 'ABC Manufacturing',
          experience: 'Forklift Operator',
          skills: JSON.stringify(['Forklift', 'Pallet Jack']),
        },
        {
          id: 2,
          companyName: 'XYZ Solutions',
          experience: 'Delivery Driver',
          skills: JSON.stringify(['Delivering Large Parcels']),
        },
        {
          id: 3,
          companyName: '123 Services',
          experience: 'Event Staff',
          skills: JSON.stringify([
            'Bartending',
            'Setup & Teardown',
            'Waitstaff',
          ]),
        },
      ]
  }
}
