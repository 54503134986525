import React, { useEffect, useMemo } from 'react'
import { Flex, Box, Field, Heading, Input, Label, useModal } from 'ui'
import { track } from 'lib/amplitude'
import { Footer } from '../../Footer'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentForm, paymentSchema, PaymentTypeEnum } from '../../schemas'
import { useShiftEditor } from '../../useShiftEditor'
import { zodResolver } from '@hookform/resolvers/zod'
import { PaymentTypeSelector } from './PaymentTypeSelector'
import { FiDollarSign } from 'react-icons/fi'
import styled from 'styled-components'
import { BonusSelector } from './BonusSelector'
import { ChargeSummary } from './ChargeSummary'
import { PayGuidance } from './PayGuidance'
import { BonusGuidance } from './BonusGuidance'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

const SHIFT_LEAD_UPCHARGE_PERCENTAGE = 20
const SHIFT_LEAD_PAY_TO_SHIFT_PAY_RATIO =
  SHIFT_LEAD_UPCHARGE_PERCENTAGE / 100 + 1

const PayBox = styled(Box)`
  position: relative;
  ${Label} {
    position: absolute;
    top: 0.7rem;
    left: 0;
  }
`

const HIGH_HOURLY_PAY_WARNING_THRESHOLD = 50

export function PaymentPage() {
  useEffect(() => {
    track('impression, ShiftEditor_Pay_page')
  }, [])
  const { value: isInternalCompany } = useGate(
    FeatureGates.InternalCompanyTreatment
  )
  const { shiftData, setShiftData, goNext, isTryout } = useShiftEditor()
  const methods = useForm<PaymentForm>({
    resolver: zodResolver(paymentSchema),
    defaultValues: shiftData?.payment,
  })
  const {
    setValue,
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = methods

  const paymentType = watch('paymentType')
  const pay = watch('pay')
  const shiftLeadPay = watch('shiftLeadPay')
  const { showModal } = useModal()
  const shiftLeadRecommendedPay = useMemo(() => {
    if (!pay) return null

    return Math.ceil(pay * SHIFT_LEAD_PAY_TO_SHIFT_PAY_RATIO)
  }, [pay])

  useEffect(() => {
    if (isInternalCompany) {
      setValue('allowZeroPay', true)
    }
  }, [isInternalCompany])

  const onSubmit = handleSubmit((data) => {
    const submitAndGoNext = () => {
      setShiftData({
        payment: data,
      })
      goNext()
    }

    if (
      data.paymentType === PaymentTypeEnum.Hourly &&
      data.pay > HIGH_HOURLY_PAY_WARNING_THRESHOLD
    ) {
      showModal({
        title: 'Confirm Hourly Rate',
        content: `The rate of $${pay}/hr seems unusually high, please confirm you have the correct rate.`,
        onOk: ({ closeModal }) => {
          closeModal()
          submitAndGoNext()
        },
      })
    } else {
      submitAndGoNext()
    }
  })

  return (
    <Box>
      <Heading level={[2, 1]} my={4}>
        Enter Pay Detail
      </Heading>
      {isTryout ? (
        <Box mb={3}>
          <TryoutLabel />
        </Box>
      ) : null}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Flex flexDirection={'row'} flexWrap={'wrap'}>
            <Box width={[1, 1, 1, 1, 3 / 5]}>
              <Heading level={3}>Select Payment Type</Heading>
              <Field mt={4} error={errors.paymentType?.message?.toString()}>
                <PaymentTypeSelector />
              </Field>
              <Heading level={3} my={4} mb={2}>
                Base Pay
              </Heading>
              <Field
                mt={2}
                label={
                  paymentType === PaymentTypeEnum.Hourly
                    ? 'Enter the hourly rate per worker for this shift'
                    : 'Enter the amount (per each worker) for the entire shift'
                }
                error={errors.pay?.message?.toString()}
              >
                <PayBox>
                  <Input
                    aria-label={'Enter Worker Pay Amount'}
                    block={true}
                    max={100000}
                    step={'0.01'}
                    type={'number'}
                    {...register('pay')}
                    placeholder={'Enter an amount'}
                    icon={<FiDollarSign />}
                  />
                  {paymentType === PaymentTypeEnum.Hourly && pay ? (
                    <Label
                      style={{
                        transform: `translateX(calc(${
                          (pay ?? 0).toString().length
                        }ch + 5rem)`,
                      }}
                    >
                      Per Hour
                    </Label>
                  ) : null}
                </PayBox>
              </Field>
              {shiftData.staff?.numShiftLeads ? (
                <Field
                  mt={2}
                  label={
                    paymentType === PaymentTypeEnum.Hourly
                      ? 'Enter the hourly rate per shift lead for this shift'
                      : 'Enter the amount (per each shift lead) for the entire shift'
                  }
                  error={errors.shiftLeadPay?.message?.toString()}
                >
                  <PayBox>
                    <Input
                      aria-label={'Enter Shift Lead Pay Amount'}
                      block={true}
                      max={100000}
                      step={'0.01'}
                      type={'number'}
                      {...register('shiftLeadPay')}
                      placeholder={
                        shiftLeadRecommendedPay
                          ? `e.g. ${shiftLeadRecommendedPay}`
                          : 'Enter an amount'
                      }
                      icon={<FiDollarSign />}
                    />
                    {paymentType === PaymentTypeEnum.Hourly && shiftLeadPay ? (
                      <Label
                        style={{
                          transform: `translateX(calc(${
                            (shiftLeadPay ?? 0).toString().length
                          }ch + 5rem)`,
                        }}
                      >
                        Per Hour
                      </Label>
                    ) : null}
                  </PayBox>
                </Field>
              ) : null}
              <PayGuidance />
              <Heading level={3} my={4} mb={2}>
                Bonus
              </Heading>
              <Field
                mt={2}
                label={'Select a one time bonus for this shift'}
                error={errors.bonus?.message?.toString()}
              >
                <BonusSelector />
              </Field>
              <Field
                mt={2}
                label={'Or Enter a Custom Bonus Amount'}
                error={errors.customBonus?.message?.toString()}
              >
                <Input
                  aria-label={'Enter Bonus Amount'}
                  type={'number'}
                  step={'0.01'}
                  {...register('customBonus')}
                  placeholder={'Enter an amount'}
                  icon={<FiDollarSign />}
                />
              </Field>
              <BonusGuidance />
            </Box>
            <Box
              width={[1, 1, 1, 1, 2 / 5]}
              pl={[0, 0, 0, 0, 5]}
              mt={[4, 4, 4, 4, 0]}
            >
              <ChargeSummary />
            </Box>
          </Flex>
          <Box width={[1, 1, 1, 1, 3 / 5]}>
            <Footer />
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}
