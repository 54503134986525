import React, { useMemo } from 'react'
import { usePreviewData } from './usePreviewData'
import { addDays, format, isSameDay, startOfWeek } from 'date-fns'
import { Box, Flex } from 'ui'

export function WeekCalendar() {
  const { date } = usePreviewData()
  const weekDays = useMemo(() => {
    const startDate = startOfWeek(date, { weekStartsOn: 1 })
    return '1234567'.split('').map((d, index) => addDays(startDate, index))
  }, [date])

  return (
    <Flex flexDirection={'row'} justifyContent={'space-between'} mt={2}>
      {weekDays.map((d) => {
        const isSelected = isSameDay(d, date)
        return (
          <Flex
            flexDirection={'column'}
            key={d.toISOString()}
            fontSize={0}
            alignItems={'center'}
          >
            {format(d, 'eeeeee')}
            <Box
              borderRadius={'50%'}
              width={'2.3rem'}
              height={'2.3rem'}
              bg={isSelected ? 'primary' : 'none'}
              color={isSelected ? 'white' : 'text'}
              mt={1}
              p={1}
              textAlign={'center'}
            >
              {format(d, 'd')}
            </Box>
          </Flex>
        )
      })}
    </Flex>
  )
}
